import useI18n from '@/composables/use-i18n'
import ReferralAds from '@/enums/ReferralAds'
import Country from '@/enums/Country'

export type ReferralAd = {
  value: number
  name: string
  img: string
  link: string
  availableCountries: Country[]
}

export default function () {
  const i18n = useI18n()

  const list: ReferralAd[] = [
    {
      value: ReferralAds.LUCKY_BO,
      name: i18n.t('insurance.luckybo').toString(),
      img: 'https://cdn.gogoout.com/web/referral-ad/luckybo.jpg?v=1',
      link: 'https://gogoout.pse.is/luckybo_confirm',
      availableCountries: [Country.TAIWAN],
    },
    {
      value: ReferralAds.MING_TAI,
      name: i18n.t('insurance.mingtai').toString(),
      img: 'https://cdn.gogoout.com/web/referral-ad/mingtai.jpg?v=1',
      link: 'https://gogoout.pse.is/397wc9',
      availableCountries: [Country.TAIWAN],
    },
    {
      value: ReferralAds.TMNEWA_OVERSEATRAVEL,
      name: i18n.t('insurance.tmnewaOverseatravel').toString(),
      img: 'https://cdn.gogoout.com/web/referral-ad/tmnewa_overseatravel.jpg?v=1',
      // 新安旅平險內部追蹤頁
      link: '/tmnewa',
      availableCountries: [Country.JAPAN, Country.KOREA, Country.THAILAND],
    },
  ]

  /**
   * 取得該國家可用的保險
   * @param country 國家
   * @returns 可用的保險
   */
  const getAvailableReferralAds = (country: Country) => {
    return list.filter(item => item.availableCountries.includes(country))
  }

  /**
   * 取得保險資訊
   * @param type 保險類型
   * @returns 保險資訊
   */
  const getInsuranceInfo = (type: ReferralAds) => {
    return list.find(item => item.value === type)
  }

  return {
    getAvailableReferralAds,
    getInsuranceInfo,
  }
}
